import { Player } from "../Player";
import { sanitizeExploits } from "./Exploit";

export function applyExploit(): void {
  if (Player.exploits && Player.exploits.length === 0) {
    return;
  }
  Player.exploits = sanitizeExploits(Player.exploits);
  const inc = Math.pow(1.001, Player.exploits.length);
  const dec = Math.pow(0.999, Player.exploits.length);

  Player.mults.hacking_chance *= inc;
  Player.mults.hacking_speed *= inc;
  Player.mults.hacking_money *= inc;
  Player.mults.hacking_grow *= inc;
  Player.mults.hacking *= inc;

  Player.mults.strength *= inc;
  Player.mults.defense *= inc;
  Player.mults.dexterity *= inc;
  Player.mults.agility *= inc;
  Player.mults.charisma *= inc;

  Player.mults.hacking_exp *= inc;
  Player.mults.strength_exp *= inc;
  Player.mults.defense_exp *= inc;
  Player.mults.dexterity_exp *= inc;
  Player.mults.agility_exp *= inc;
  Player.mults.charisma_exp *= inc;

  Player.mults.company_rep *= inc;
  Player.mults.faction_rep *= inc;

  Player.mults.crime_money *= inc;
  Player.mults.crime_success *= inc;

  Player.mults.hacknet_node_money *= inc;
  Player.mults.hacknet_node_purchase_cost *= dec;
  Player.mults.hacknet_node_ram_cost *= dec;
  Player.mults.hacknet_node_core_cost *= dec;
  Player.mults.hacknet_node_level_cost *= dec;

  Player.mults.work_money *= inc;
}
